<template>
    <div class="dashboard-coins">
        <div class="how-earn-table">
            <DashCardRouter title="Monedas" class="mb-2" />
            <div class="how-earn-table__head">
                <div class="how-earn-table__row">
                    <div class="how-earn-table__col">
                        <p>{{ $lang.pages.dashboardMonedas.action }}</p>
                    </div>
                    <div class="how-earn-table__col">
                        <p>{{ $lang.pages.dashboardMonedas.user_not_verified }}</p>
                    </div>
                    <div class="how-earn-table__col">
                        <div>
                            <img :src="$assets.primary.verified" alt="Verificado" />
                            <p>{{ $lang.pages.dashboardMonedas.verified_user }}</p>
                        </div>
                        <div class="how-earn-table__col-info">
                            <div><i class="fas fa-info-circle fas-sm"></i></div>
                            <p>{{ $lang.pages.dashboardMonedas.information }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="how-earn-table__body">
                <div v-for="(row, index) in howEarn" :key="index" class="how-earn-table__row">
                    <div class="how-earn-table__col">
                        {{ row.action }}
                    </div>
                    <div class="how-earn-table__col">
                        <p class="points">
                            {{ row.pointsForUnverified.count }} {{ $lang.pages.dashboardMonedas.coins }}
                        </p>
                        <p class="help">
                            {{ row.pointsForUnverified.frequency }}
                        </p>
                    </div>
                    <div class="how-earn-table__col">
                        <p class="points">
                            {{ row.pointsForVerified.count }}
                            {{ $lang.pages.dashboardMonedas.coins }}
                        </p>
                        <p class="help">
                            {{ row.pointsForVerified.frequency }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="history-table">
            <h2 class="history-table__title">
                <p>{{ $lang.pages.dashboardMonedas.historical_events }}</p>
                <div class="history-table__title-info">
                    <div><i class="fas fa-info-circle fas-sm text-[#ABA3AF]"></i></div>
                    {{ $lang.pages.dashboardMonedas.information }}
                </div>
            </h2>
            <template v-if="coinsData.results.length">
                <div class="history-table__head">
                    <div class="history-table__row">
                        <div class="history-table__col">
                            <p>{{ $lang.pages.dashboardMonedas.date }}</p>
                        </div>
                        <div class="history-table__col">
                            <p>{{ $lang.pages.dashboardMonedas.action }}</p>
                        </div>
                        <div class="history-table__col">
                            <p>{{ $lang.pages.dashboardMonedas.received_coins }}</p>
                        </div>
                    </div>
                </div>
                <div class="history-table__body">
                    <div v-for="(row, index) in coinsData.results" :key="index" class="history-table__row">
                        <div v-if="row.created_at" class="history-table__col">
                            {{ formatDate(row.created_at) }}
                        </div>
                        <div class="history-table__col">
                            <div class="action">
                                <p class="action__title">
                                    {{ row.action }}
                                </p>
                                <div class="action__resume">
                                    <p v-if="row.description">{{ row.description }}</p>
                                    <NuxtLink
                                        no-prefetch
                                        v-if="
                                            row.properties &&
                                            row.properties.model &&
                                            row.properties.model.url_front &&
                                            row.properties.model.title
                                        "
                                        :to="row.properties.model.url_front"
                                    >
                                        {{ row.properties.model.title }}
                                    </NuxtLink>
                                </div>
                            </div>
                        </div>
                        <div class="history-table__col">
                            <p class="points">{{ row.amount }} {{ $lang.pages.dashboardMonedas.coins }}</p>
                        </div>
                    </div>
                </div>
            </template>
            <MiscNoResults v-else mode="activities" title="Todavía no has ganado monedas." />
            <ButtonLoadMore v-if="fetchButton.show" :loading="fetchButton.loading" :handler="fetch" />
        </div>
        <div class="faq">
            <h2 class="faq__title">Preguntas frecuentes</h2>
            <ul class="faq__list">
                <li v-for="(faq, index) in faqs" :key="index" class="faq__item">
                    <button
                        class="faq__button"
                        type="button"
                        @click="
                            activeFaq =
                                typeof activeFaq === 'number' ? (activeFaq === index ? null : index) : index
                        "
                    >
                        {{ faq.title }}
                        <img
                            :src="$assets.primary.arrowDown"
                            :class="{
                                rotate: activeFaq === index,
                            }"
                            alt="icon"
                        />
                    </button>
                    <transition name="slide-down">
                        <div v-if="activeFaq === index" class="faq__desc" v-html="faq.description"></div>
                    </transition>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { format, parseISO } from 'date-fns'
import { es as locale } from 'date-fns/locale'
import { useAuthStore } from '~/store/auth'
import type { Models } from '~/types/models'

import type { Api } from '~~/global'

const { UserData, SessionToken } = useAuthStore()
const { $lang } = useNuxtApp()

const params = reactive({
    per_page: 10,
    page: 1,
})
const { buildHeaders, baseURL, endpoints } = useApiConfig()
const getCoins = () =>
    $fetch<Api.Responses.General.IndividualModel<Models.Coin>>(
        endpoints.general.coinHistory.path.replace('_ID_', String(UserData?.id || '')),
        {
            headers: buildHeaders(SessionToken),
            baseURL,
            method: 'GET',
            params,
        },
    )

const { data: responseData, error } = await useAsyncData('dashboard-user-activities', () =>
    getCoins().catch((e) => e.data),
)

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: 404,
        message: responseData.value?.feedback || error.value?.message || 'Error data',
    })
}

params.page = 2

const coinsData = ref(responseData.value.data)

const activeFaq = ref(null) as Ref<null | number>

const fetchButton = reactive({
    loading: false,
    show:
        coinsData.value.meta.total > 0 && coinsData.value.meta.current_page < coinsData.value.meta.last_page,
})

const howEarn = [
    {
        action: $lang.pages.dashboardMonedas.register_login,
        pointsForVerified: {
            count: 10,
            frequency: $lang.pages.dashboardMonedas.action_1_time,
        },
        pointsForUnverified: {
            count: 10,
            frequency: $lang.pages.dashboardMonedas.action_1_time,
        },
    },
    {
        action: $lang.pages.dashboardMonedas.comments,
        pointsForVerified: {
            count: 10,
            frequency: $lang.pages.dashboardMonedas.action_5_time_per_day,
        },
        pointsForUnverified: {
            count: 10,
            frequency: $lang.pages.dashboardMonedas.action_5_time_per_day,
        },
    },
    {
        action: $lang.pages.dashboardMonedas.report_expired_offer,
        pointsForVerified: {
            count: 10,
            frequency: $lang.pages.dashboardMonedas.action_5_time_per_day_approved,
        },
        pointsForUnverified: {
            count: 10,
            frequency: $lang.pages.dashboardMonedas.action_5_time_per_day_approved,
        },
    },
    {
        action: $lang.pages.dashboardMonedas.offer_published,
        pointsForVerified: {
            count: 40,
            frequency: $lang.pages.dashboardMonedas.action_1_time_per_day_if_approved,
        },
        pointsForUnverified: {
            count: 40,
            frequency: $lang.pages.dashboardMonedas.action_1_time_per_day_if_approved,
        },
    },
    {
        action: $lang.pages.dashboardMonedas.coupon_published,
        pointsForVerified: {
            count: 50,
            frequency: $lang.pages.dashboardMonedas.action_1_time_per_day_if_approved,
        },
        pointsForUnverified: {
            count: 50,
            frequency: $lang.pages.dashboardMonedas.action_1_time_per_day_if_approved,
        },
    },
    {
        action: $lang.pages.dashboardMonedas.discount_reach_250,
        pointsForVerified: {
            count: 10,
            frequency: $lang.pages.dashboardMonedas.action_no_limits,
        },
        pointsForUnverified: {
            count: 10,
            frequency: $lang.pages.dashboardMonedas.action_no_limits,
        },
    },
    {
        action: $lang.pages.dashboardMonedas.discount_reach_500,
        pointsForVerified: {
            count: 50,
            frequency: $lang.pages.dashboardMonedas.action_no_limits,
        },
        pointsForUnverified: {
            count: 50,
            frequency: $lang.pages.dashboardMonedas.action_no_limits,
        },
    },
    {
        action: $lang.pages.dashboardMonedas.check_in_daily,
        pointsForVerified: {
            count: 10,
            frequency: $lang.pages.dashboardMonedas.action_5_time_per_day,
        },
        pointsForUnverified: {
            count: 10,
            frequency: $lang.pages.dashboardMonedas.action_5_time_per_day,
        },
    },
    {
        action: $lang.pages.dashboardMonedas.antiquity_1_month,
        pointsForVerified: {
            count: 10,
            frequency: $lang.pages.dashboardMonedas.action_1_time,
        },
        pointsForUnverified: {
            count: 10,
            frequency: $lang.pages.dashboardMonedas.action_1_time,
        },
    },
    {
        action: $lang.pages.dashboardMonedas.antiquity_6_month,
        pointsForVerified: {
            count: 50,
            frequency: $lang.pages.dashboardMonedas.action_1_time,
        },
        pointsForUnverified: {
            count: 50,
            frequency: $lang.pages.dashboardMonedas.action_1_time,
        },
    },
    {
        action: $lang.pages.dashboardMonedas.aniversary,
        pointsForVerified: {
            count: 250,
            frequency: $lang.pages.dashboardMonedas.action_1_time_anually,
        },
        pointsForUnverified: {
            count: 250,
            frequency: $lang.pages.dashboardMonedas.action_1_time_anually,
        },
    },
]
const faqs = [
    {
        title: $lang.pages.dashboardMonedas.coins_are_free,
        description: $lang.pages.dashboardMonedas.coins_are_free_answer,
    },
    {
        title: $lang.pages.dashboardMonedas.can_buy_coins,
        description: $lang.pages.dashboardMonedas.can_buy_coins_answer,
    },
    {
        title: $lang.pages.dashboardMonedas.how_can_use_coins,
        description: $lang.pages.dashboardMonedas.how_can_use_coins_answer,
    },
]

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

const title = $lang.pages.dashboardMonedas.title_seo
const description = $lang.pages.dashboardMonedas.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const fetch = async () => {
    fetchButton.loading = true

    const { feedback, data } = await getCoins()

    if (feedback === 'data_success') {
        if (data.meta.current_page === 1) {
            coinsData.value.results = [...data.results]
        } else {
            coinsData.value.results = [...data.results, ...coinsData.value.results]
        }
        if (data.meta.current_page < data.meta.last_page) {
            params.page = data.meta.current_page + 1
        } else {
            fetchButton.show = false
        }
    }

    fetchButton.loading = false
}
const formatDate = (date: string) => {
    return format(parseISO(date), 'dd MMM yyyy HH:mm', { locale })
}
</script>

<style lang="postcss" scoped>
.dashboard-coins {
    @apply space-y-3;
    .how-earn-table {
        @apply bg-white p-2 lg:rounded-xl;
        &__row {
            @apply flex h-16 items-center lg:h-10;
        }
        &__col {
            @apply w-1/3 flex-none px-2 lg:px-3;
        }
        &__head {
            .how-earn-table__col {
                @apply text-sm font-semibold text-site-primary;
                &:nth-child(3) {
                    @apply flex h-full items-center justify-between rounded-t-xl bg-[#FAF6FF] font-semibold text-site-primary;
                    div:nth-child(1) {
                        @apply flex items-center gap-1;
                    }
                    img {
                        @apply h-4 w-4;
                    }
                }
                &-info {
                    @apply flex items-center justify-end gap-1 text-[0.60rem] text-[#6B6870];
                    & > div {
                        @apply text-[#ABA3AF];
                    }
                    & > p {
                        @apply font-normal;
                    }
                }
            }
        }
        &__body {
            .how-earn-table__row {
                @apply flex h-20 overflow-hidden text-xs lg:h-16;
            }
            .how-earn-table__col {
                .info-icon {
                    @apply inline-block h-4 w-4 rounded-full text-center text-xs text-white;
                }
                .points {
                    @apply text-xs font-medium text-[#1B1B1B];
                }
                .help {
                    @apply text-xs font-normal text-[#94949B];
                }
                &:nth-child(1) {
                    @apply font-semibold;
                }
                &:nth-child(1),
                &:nth-child(2) {
                    @apply h-full py-4;
                }
                &:nth-child(3) {
                    @apply h-full bg-[#FAF6FF] py-4;
                }
            }
        }
    }
    .history-table {
        @apply bg-white p-2 lg:rounded-xl;
        &__title {
            @apply flex items-center justify-between border-b border-opacity-40 px-2 pb-2;
            p {
                @apply text-base font-semibold text-[#1B1B1B] lg:text-lg;
            }
            &-info {
                @apply flex items-center gap-1 text-sm font-medium text-[#6B6870];
            }
        }
        &__head {
            .history-table__row {
                @apply font-semibold text-site-primary;
            }
        }
        &__row {
            @apply flex py-3;
        }
        &__col {
            @apply w-1/2 flex-none px-2 text-xs;
            &:nth-child(1),
            &:nth-child(3) {
                @apply w-1/4;
            }
            &:nth-child(2) {
                .action {
                    &__title {
                        @apply text-sm font-semibold;
                    }
                    &__resume {
                        @apply flex flex-col;
                        p {
                            @apply font-sans text-xs text-[#94949B];
                        }
                        a {
                            @apply overflow-hidden truncate text-ellipsis text-xs font-medium text-site-primary;
                        }
                    }
                }
            }
        }
        &__body {
            @apply pb-2;
            .history-table__row {
                @apply flex py-3 text-[#6B6870];
                &:not(:last-of-type) {
                    @apply mb-3;
                }
            }
            .history-table__col {
                &:nth-child(1) {
                    @apply text-[#94949B];
                }
                &:nth-child(2) {
                    @apply font-semibold text-[#1B1B1B];
                }
                &:nth-child(3) {
                    @apply font-medium text-[#6B6870];
                }
            }
        }
    }
    .faq {
        @apply bg-white p-2 lg:rounded-xl;
        &__title {
            @apply border-b border-opacity-40 px-2 pb-2 text-base font-semibold text-[#1B1B1B] lg:text-lg;
        }
        &__list {
            @apply mt-2 space-y-3 px-2;
        }
        &__item {
            @apply py-2;
            &:not(:last-of-type) {
                @apply border-b border-gray-200;
            }
        }
        &__button {
            @apply mb-2 flex w-full items-center justify-between text-left font-medium text-[#1B1B1B];
            img {
                @apply ml-4 h-4 w-4 transition-transform;
                &.rotate {
                    @apply -rotate-180 transform;
                }
            }
        }
        &__desc {
            @apply pb-2 text-sm text-[#6B6870];
        }
    }
}
</style>
